import type {CookieRef, NuxtApp} from "#app";

export default class ClientGlobals {
    public static async logOutIfUnAuthorized(response: Response, token: CookieRef<string | null | undefined>, ctx?: NuxtApp | undefined): Promise<boolean> {
        if (response.status != 401)
            return false;
        //Clear the login cookie
        token.value = null;
        //Redirect to login page
        if (ctx != null)
            await ctx.runWithContext(async () => await navigateTo("/login"));
        else
            await navigateTo("/login");
        return true;
    }

    public static started: boolean = false;
}
